:root {
  --highlight-rgb: 0, 0, 0;
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
  --header-bg-rgb: 3, 105, 161;
  --accent-rgb: 2, 132, 199;
  --header-foreground-rgba: 255, 255, 255, 0.8;
  --transparent: 0.8;
}

@media (prefers-color-scheme: dark) {
  :root {
    --highlight-rgb: 255, 255, 255;
    --foreground-rgb: 209, 213, 219;
    --background-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.App-main-section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.App-content {
  padding: 1em;
  flex-grow: 1;
}

a {
  color: rgb(var(--accent-rgb));
  text-decoration: none;
}

a:hover {
  color: rgb(var(--highlight-rgb));
}

.App-main h1 {
  font-size: calc(1em + 2.2vmin);
  margin-top: 0.5em;
  margin-bottom: 1em;
  opacity: 0.85;
}

.App-main h2 {
  font-size: calc(1em + 2vmin);
  margin-top: 0.5em;
  margin-bottom: 1em;
  opacity: 0.7;
}

.App-home-link {
  height: calc(1.5em + 2vmin);
  padding: 0.2em;
}

.App-logo {
  height: calc(1.5em + 2vmin);
}

.App-header {
  background-color: rgb(var(--header-bg-rgb));
  color: #fff;
  padding: 0.5em;
  font-size: calc(10px + 2vmin);
  user-select: none;
}

.App-header .bx {
  font-size: calc(20px + 2vmin);
}

.App-header nav {
  display: flex;
  gap: 1vh;
  justify-content: space-between;
}

.App-header span,
.App-nav-link {
  padding: 0.5em;
}

.App-header a {
  color: #fff;
  opacity: var(--transparent);
}

.App-header a:hover,
.App-title:hover {
  opacity: 1;
}

.App-title {
  font-weight: bold;
  opacity: var(--transparent);
  cursor: pointer;
}

.App-header .App-title i {
  font-size: calc(4px + 2vmin);
}

.App-header nav.App-translations {
  justify-content: center;
}

.App-translations ul {
  text-align: center;
  padding-inline-start: 0;
  margin-block-start: 1em;
  margin-block-end: 1em;
  list-style-type: none;
}

.App-translations li {
  padding-bottom: 1em;
}

.App-link {
  color: rgb(var(--accent-rgb));
}

.App-footer {
  background-color: #282c34;
  color: #aaa;
  padding: 2em 1em 4em 1em;
  font-size: calc(8px + 1vmin);
  text-align: center;
  line-height: 1.5em;
  user-select: none;
}

.App-footer-links {
  margin-top: 1em;
}

.App-footer a:hover {
  color: #fff;
}

.App-home ul {
  list-style-type: none;
  padding: 0;
}

.App-home li {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.App-books {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.App-books a {
  padding: 1em;
}

.App-chapters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;
  font-size: 1.25em;
}

.App-chapters a {
  padding: 1em;
}

.App-verse-number {
  opacity: 50%;
  padding-right: 0.5em;
  vertical-align: top;
  font-size: 0.8em;
  user-select: none;
}

.App-verses {
  margin-bottom: 2em;
}

.App-verse {
  display: block;
  margin-bottom: 1em;
}

.App-versenav {
  display: flex;
  justify-content: space-between;
  font-size: 1.875em;
  user-select: none;
}

.App-search-form {
  display: flex;
  gap: 1em;
}

.App-search-input {
  flex-grow: 1;
  padding: 0.5em;
  margin-bottom: 1em;
  font-size: 1.25em;
  border-width: 2px;
  border-radius: 0;
  border-style: solid;
  border-color: rgb(var(--accent-rgb));
  outline: 0;
}

.App-search-input:focus {
  outline-width: 2px;
  outline-style: solid;
  outline-color: rgb(var(--accent-rgb));
}

.App-result-amount {
  text-align: right;
}

.App-highlight {
  background-color: #fbbf24;
}

.App-bold {
  font-weight: bold;
}

.App-selected {
  text-decoration: underline;
}

.App-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App-spinner {
  width: 4em;
  height: 4em;
  border: 0.4em solid #888;
  border-top: 0.4em solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App-large-icon {
  font-size: 2em;
}

.App-cross-references {
  font-size: 0.8em;
  user-select: none;
}

.App-cross-references a::after {
  content: ", ";
}

/* Remove the comma after the last link */
.App-cross-references a:last-child::after {
  content: "";
}

.App-audio-player-container {
  background-color: rgb(var(--header-bg-rgb));
  padding: 1em;
}

.App-audio-player {
  opacity: 0.8;
}

.App-audio-player:hover {
  opacity: 1;
}

.App-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.App-audio-player {
  width: 100%;
  height: 3em;
}

.App-share {
  background-color: #282c34;
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 5em;
  user-select: none;
}

.App-share-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 2em 1em;
}

.App-share img {
  height: 0.8em;
  opacity: 0.8;
}

.App-share img:hover {
  opacity: 1;
}

.App-share a {
  color: rgba(255, 255, 255, 0.8);
}

.App-share a:hover {
  color: rgba(255, 255, 255, 1);
}

.App-share button {
  font-size: 2em;
  padding: 0.1em;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: transform 0.4s ease-out, color 0.4s ease-out;
}

.App-share button:hover {
  color: rgba(255, 255, 255, 1);
}

.App-share button:active {
  transform: scale(0.95);
  color: rgb(var(--accent-rgb));
  transition: transform 0s ease-out, color 0s ease-out;
}